import Maps from "./Maps";
import PaperTexture from "../images/Img_texture_papier_gris.png"
import Socials from "./Socials";
import Location from "./Location";
import { useContext } from "react";
import MobileContext from "../context/mobile";

function Footer({goToTop}) {
	const {isMobile} = useContext(MobileContext);

	return (
		<div className="lg:h-[30%]
							h-[20%] w-screen bg-[#dcdad0] sticky top-[100%] flex flex-row flex-wrap items-center bg-contain font-Montserrat" style={{ backgroundImage: `url(${PaperTexture})` }}>
			<Socials goToTop={goToTop}/>
			{!isMobile && <div className="w-[37%]"/>}
			<Location goToTop={goToTop}/>
			{!isMobile && <Maps />}
		</div>
	)
}

export default Footer;