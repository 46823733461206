import { useContext } from "react";
import NavigationContext from "../context/navigation";

function Location ({goToTop}) {
	const { navigate } = useContext(NavigationContext);

	const handleClickContact = (event) => {
		if (event.metaKey || event.ctrlKey)
			return;
		event.preventDefault();

		navigate("/contact");
		goToTop();
	};

	return (
		<div className="lg:w-[20%] lg:mt-[0%] lg:mb-[3%]
							w-[50%] flex flex-col flex-wrap h-fit mr-[2%] mt-[3%] mb-[3%]">
			<p className="lg:text-[1vw]
							text-[3vw] mr-[2%] mb-[1%] text-right font-bold">LE STUDIO</p>
			<p className="lg:text-[1vw]
							text-[3vw] mr-[2%] mb-[1%] text-right underline"><span className="cursor-pointer " onClick={handleClickContact}>Privé - *sur RDV uniquement*</span></p>
			<p className="lg:text-[1vw]
							text-[3vw] mr-[2%] mb-[1%] text-right">3 rue Rivet</p>
			<p className="lg:text-[1vw]
							text-[3vw] mr-[2%] mb-[1%] text-right">69001 Lyon</p>
		</div>
	)
}

export default Location;